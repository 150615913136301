// If you want to override variables do it here
@import "variables";

$primary-color: #2199e8 !default;
$primary: #2199e8 !default;

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// If you want to add something do it here
@import "custom";
